import Auth from './views/Auth'
import Docs from './views/Docs'
import Lead from './views/Lead'
import Contact from './views/Contact'
import Repaired from './views/Repaired'
import DocsApi from './views/DocsApi'
import NotFound from './views/NotFound'
import { VueCookieNext } from 'vue-cookie-next'
import image from "./assets/img/favicon.png";
import axios from 'axios'

import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: '/',
    name: 'lead',
    component: Lead,
    meta: { auth: true, title: 'Передать заказ' }
  },
  {
    path: '/',
    name: 'docs',
    component: Docs,
    meta: { auth: true, title: 'Заказы' }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: { auth: true, title: 'Связаться с менеджером' }
  },
  {
    path: '/repaired',
    name: 'repaired',
    component: Repaired,
    meta: { auth: true, title: 'Техника и города' }
  },
  {
    path: '/docsapi',
    name: 'docsapi',
    component: DocsApi,
    meta: { auth: true, title: 'API' }
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: { auth: false, title: 'Партнерские программы' }
  },
  {
    path: '/not-found',
    name: 'notFound',
    component: NotFound
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: 'notFound' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' - РА Созвездие' || 'РА Созвездие';

  axios.get("/api/ipinfo?q=" + Math.random()).then((response) => {
    VueCookieNext.setCookie('client_ip', response.data.ip)
  });

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }
  link.href = image;

  if (to.matched.some(record => record.meta.auth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!VueCookieNext.isCookieAvailable('token')) {
      next({
        path: '/auth',
        query: { redirect: to.auth }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router