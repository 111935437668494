<template>
  <div class="login-screen">
    <loading
      v-model:active="isLoading"
      color="red"
      background-color="#000000"
      :width="100"
      :height="100"
      :opacity="0.7"
      loader="dots"
    />
    <div class="container-fluid h-100">
      <div class="row h-100">
        <div class="col-md-12">
          <div class="login-screen__wrap">
            <div class="login-screen__mobile d-flex d-md-none">
              <a href="/" class="login-screen__logo"></a>
              <div class="login-screen__desc">партнерские программы</div>
            </div>

            <div class="login-screen__col">
              <div class="login-screen__logo-line d-md-flex align-items-center">
                <a href="/" class="login-screen__logo d-none d-md-flex"></a>
              </div>
              <h1 class="d-none d-md-block">
                <strong>РА Созвездие</strong> &mdash; партнерские программы
              </h1>

              <div class="login-screen__slider">
                <div class="advantages swiper-container">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <div class="advantages__item">
                        <div class="advantages__img advantages__img--1"></div>
                        <div class="advantages__wrap">
                          <div class="advantages__title">Удобство</div>
                          <div class="advantages__text">
                            Исчерпывающая информация о состоянии заказов по
                            совместным проектам.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-slide">
                      <div class="advantages__item">
                        <div class="advantages__img advantages__img--2"></div>
                        <div class="advantages__wrap">
                          <div class="advantages__title">Прозрачность</div>
                          <div class="advantages__text">
                            Все необходимые для взаимодействия отчёты, журналы и
                            справочники.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-slide">
                      <div class="advantages__item">
                        <div class="advantages__img advantages__img--3"></div>
                        <div class="advantages__wrap">
                          <div class="advantages__title">Гибкость</div>
                          <div class="advantages__text">
                            Индивидуальные варианты сотрудничества для каждого
                            участника проекта.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-slide">
                      <div class="advantages__item">
                        <div class="advantages__img advantages__img--4"></div>
                        <div class="advantages__wrap">
                          <div class="advantages__title">Поддержка</div>
                          <div class="advantages__text">
                            Вся необходимая документация, примеры интеграций,
                            помощь в решении проблем.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-pagination"></div>
                </div>
              </div>
            </div>

            <div class="login-screen__form">
              <div class="login-screen__form-wrapper">
                <h2>Вход</h2>
                <form @submit.prevent="checkForm" action="#" method="post">
                  <label for="login">
                    <input
                      id="login"
                      type="text"
                      v-model="agentId"
                      :disabled="isLoading"
                      autocomplete="off"
                    />
                    <span class="login-screen__form-placeholder">Логин</span>
                  </label>

                  <label for="password">
                    <input
                      id="password"
                      type="password"
                      v-model="password"
                      :disabled="isLoading"
                      autocomplete="off"
                    />
                    <span class="login-screen__form-placeholder">Пароль</span>
                  </label>

                  <div class="login-screen__form-row">
                    <div
                      class="login-screen__form-tippy"
                      v-tippy="{
                        content:
                          'Необходимо заключить договор о партнерском обслуживании. Обратитесь в отдел партнерских программ.',
                      }"
                    >
                      Как получить доступ?
                    </div>
                    <div class="login-screen__form-submit">
                      <input
                        type="submit"
                        value="Войти"
                        :disabled="isLoading"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $backend from "../backend";
import Loading from "vue-loading-overlay";
import { initSlider } from "../slider";
export default {
  name: "auth",
  data() {
    return {
      isLoading: true,
      agentId: "",
      password: "",
    };
  },
  components: {
    Loading,
  },
  created() {
    this.logout();
  },
  mounted() {
    initSlider();
    this.toggleInputPlaceholders();
    this.isLoading = false
  },
  methods: {
    checkForm() {
      if (
        this.agentId === "" ||
        this.password === "" ||
        !/\d+$/.test(this.agentId)
      ) {
        this.$swal({ icon: "error", text: "Пара логин/пароль неверная." });
      } else {
        this.login();
      }
    },
    login() {
      this.isLoading = true;
      this.$recaptcha("auth").then((captchaToken) => {
        $backend
          .login(this.agentId, this.password, captchaToken)
          .then(() => {
            this.$store.dispatch("login");
          })
          .then(() => {
            this.$router.push({ name: "lead" });
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    logout() {
      this.$store.dispatch("logout");
    },
    toggleInputPlaceholders() {
      const elements = document.querySelectorAll(".login-screen__form input");
      elements.forEach((el) => {
        el.addEventListener("focus", function () {
          this.classList.add("active");
        });
        el.addEventListener("blur", function () {
          if (this.value === "") {
            this.classList.remove("active");
          }
        });
      });
    },
  },
};
</script>
