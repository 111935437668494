<template>
  <header class="header">
    <div class="row justify-content-between align-items-center text-sm-left">
      <div class="col-sm-1 my-2">
        <a href="/" class="logo"><img src="~@/assets/img/logo.svg" alt /></a>
      </div>
      <div class="col-sm-8 my-md-2 my-xs-0">
        <div class="nav">
          <router-link :to="{ name: 'lead' }">Передать заказ</router-link>
          <router-link :to="{ name: 'docs' }">Заявки и заказы</router-link>
          <router-link :to="{ name: 'contact' }">Связаться с менеджером</router-link>
          <router-link :to="{ name: 'repaired' }">Техника и города</router-link>
          <router-link :to="{ name: 'docsapi' }">API</router-link>
        </div>
      </div>
      <div class="col-sm-auto my-md-2 my-xs-0">
        <div class="user">
          <span class="user__item user__logout" @click="$router.push({ name: 'auth' })">Выйти</span>
        </div>
      </div>
    </div>
  </header>
  <button class="hamburger hamburger--slider" type="button" :class="{ 'is-active': showMobileMenu }"
    @click="showMobileMenu = !showMobileMenu">
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>
  <div class="mobile-nav" :class="{ active: showMobileMenu }">
    <ul>
      <li>
        <router-link :to="{ name: 'docs' }">Заявки и заказы</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'lead' }">Передать заказ</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'contact' }">Связаться с менеджером</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'repaired' }">Техника и города</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'docsapi' }">API</router-link>
      </li>
      <li>
        <span class="mobile-nav-exit" @click="$router.push({ name: 'auth' })">Выйти</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "top",
  props: {},
  data() {
    return {
      showMobileMenu: false,
    };
  },
  methods: {},
};
</script>